<template>
    <div>
        <vs-table search stripe border description :sst="true" :data="table.data" :max-items="table.length"
            :total="table.total" @search="handleSearch" @change-page="handleChangePage">
            <template slot="header">
                <vs-dropdown vs-trigger-click class="cursor-pointer">
                    <div
                        class="flex items-center justify-between p-3 font-medium border border-solid rounded-full cursor-pointer d-theme-border-grey-light d-theme-dark-bg">
                        <span class="mr-2">{{ this.table.start }} - {{ this.table.end }} of
                            {{ this.table.total }}</span>
                        <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                    </div>
                    <vs-dropdown-menu>
                        <vs-dropdown-item v-for="item in table.limits" :key="item" @click="handleChangelength(item)">
                            <span>{{ item }}</span>
                        </vs-dropdown-item>
                    </vs-dropdown-menu>
                </vs-dropdown>
                <vs-button 
                    class="ml-3"
                    color="success" 
                    icon-pack="feather" 
                    icon="icon-check" 
                    title="Edit"
                    style="margin-right: 5px" 
                    @click="handleMultiConfirm()">Confirm Selected</vs-button>
            </template>
            <template slot="thead">
                <vs-th>
                    <vs-checkbox v-on:click="confirmAll()" :value="statusConfirmAll" color="primary"></vs-checkbox>
                </vs-th>
                <vs-th>Action</vs-th>
                <vs-th sort-key="">Supplier Data</vs-th>
                <vs-th sort-key="">PO Data</vs-th>
                <vs-th sort-key="">PO Date</vs-th>
                <vs-th sort-key="">GR Data</vs-th>
                <vs-th sort-key=""># of SKU</vs-th>
                <vs-th sort-key="">Total Qty {HU/Qty}</vs-th>
                <vs-th sort-key="">Passed</vs-th>
                <vs-th sort-key="">Damaged</vs-th>
                <vs-th sort-key="">Expired</vs-th>
                <vs-th sort-key="">Status</vs-th>
            </template>
            <template slot-scope="{data}">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <td>
                        <vs-checkbox v-if="tr.status_putaway == 1" v-on:click="checkConfirm(tr.id, indextr)" :value="tr.status_checked" color="primary"></vs-checkbox>
                    </td>
                    <vs-td>
                        <div class="flex vx-input-group">
                            <vs-button size="small" color="warning" icon-pack="feather" icon="icon-edit" title="Edit"
                                style="margin-right: 5px" @click="handleCreateCheck(tr.id)" />
                            <!-- <vs-button v-if="tr.status_putaway == 1" size="small" color="grey" icon-pack="feather" icon="icon-printer" title="Print"
                                style="margin-right: 5px" @click="handlePrintPdf(tr.id)" /> -->
                            <vs-button v-if="tr.status_putaway == 1" size="small" color="success" icon-pack="feather" icon="icon-check" title="Completed"
                                @click="handleCompleted(tr.id)" />
                        </div>
                    </vs-td>
                    <vs-td>
                        <div class="flex flex-col content-center">
                            <div>Code : {{ tr.supplier_code }}</div>
                            <div>Name : {{ tr.supplier_name }}</div>
                            <div>City : {{ tr.supplier_city }}</div>
                        </div>
                    </vs-td>
                    <vs-td>
                        <div class="flex flex-col content-center">
                            <div>Warehouse : {{ tr.warehouse.name }}</div>
                            <div>Code : {{ tr.po_code }}</div>
                            <div>PayTerm : {{ tr.payment_term_name }}</div>
                            <div>PostDate : {{ formatDate(tr.purchase_posting_date) }}</div>
                            <div>Purchase Type : {{ tr.purchase_type_name }}</div>
                        </div>
                    </vs-td>
                    <vs-td>
                        <div class="flex flex-col content-center">
                            <div>Date : {{ formatDate(tr.purchase_date) }}</div>
                            <div>ETA : {{ formatDate(tr.eta) }}</div>
                        </div>
                    </vs-td>
                    <vs-td>
                        <div class="flex flex-col content-center">
                            <div class="flex flex-col content-center">
                                <span>Code : {{ tr.code }} </span>
                                <span>DON : {{ tr.delivery_order_number }} </span>
                                <span>SN : {{ tr.shipment_number }} </span>
                            </div>
                        </div>
                    </vs-td>
                    <vs-td>
                        <div class="flex flex-col content-center">
                            <div v-if="tr.hu_count > 0">{{ tr.hu_count }}</div>
                        </div>
                    </vs-td>
                    <vs-td>
                        <div v-if="tr.hu_lines">
                            <div v-for="(qty, hu) in tr.hu_lines" :key="hu" class="flex flex-col content-center">
                                <span>{{ qty }} {{ hu }}</span>
                            </div>
                        </div>
                    </vs-td>
                    <vs-td>
                        <div class="flex flex-col content-center">
                            <div v-if="tr.passed_line">
                                <div v-for="(qty, p) in tr.passed_line" :key="p" class="flex flex-col content-center">
                                    <span>{{ qty }} {{ p }}</span>
                                </div>
                            </div>
                        </div>
                    </vs-td>
                    <vs-td>
                        <div class="flex flex-col content-center">
                            <div v-if="tr.damaged_line">
                                <div v-for="(qty, d) in tr.damaged_line" :key="d" class="flex flex-col content-center">
                                    <span>{{ qty }} {{ d }}</span>
                                </div>
                            </div>
                        </div>
                    </vs-td>
                    <vs-td>
                        <div class="flex flex-col content-center">
                            <div v-if="tr.expired_line">
                                <div v-for="(qty, e) in tr.expired_line" :key="e" class="flex flex-col content-center">
                                    <span>{{ qty }} {{ e }}</span>
                                </div>
                            </div>
                        </div>
                    </vs-td>
                    <vs-td>
                        <div class="flex flex-col content-center">
                            <div>[{{ tr.is_partial == "1" ? 'Part' : 'Main' }}] {{ tr.po_status }}</div>
                        </div>
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <vs-pagination style="padding-top: 5px" :total="table.totalPage" v-model="setPage" />
    </div>
</template>
<script>
import moment from "moment";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
export default {
    components: {},
    props: {
        draw: {
            type: Number,
        },
        poTypeId: {
            type: Number,
        },
        sourceWarehouseIds: {
            type: String,
        },
        poIds: {
            type: String,
        },
        supplierIds: {
            type: String,
        },
        isGetData: {
            type: Boolean,
        },
        // transactionDate: {
        //     type: Date,
        //     default: null,
        // },
        // rangeDate: {
        //     default: null,
        // },
        status: {
            type: String,
        },
    },
    data() {
        return {
            deleteId: null,
            table: this.tableDefaultState(),
            checkedAll: false,
            checked: [],
            selectedRouteAssignmentToAssign: [],
            timeOuts: null,
            statusConfirmAll: false,
            prIDs: [],
        };
    },
    methods: {
        tableDefaultState() {
            return {
                data: [],
                length: 10,
                page: 1,
                search: "",
                order: "id",
                sort: "desc",
                total: 0,
                totalPage: 1,
                totalSearch: 0,
                limits: [10, 25, 50, 100, "All"],
                start: 1,
                end: 0,
            };
        },
        handleSearch(searching) {
            this.table.search = searching;
            this.table.page = 1;
            if (this.timeOuts) { clearTimeout(this.timeOuts) }
            this.timeOuts = setTimeout(this.getData, 900);
        },
        handleChangePage(page) {
            this.table.page = page;
            this.getData();
        },
        handleSort(key, active) {
            this.table.order = key;
            this.table.sort = active;
            this.getData();
        },
        handleChangelength(val) {
            this.table.length = val == "All" ? this.table.total : val;
            this.table.page = 1;
            this.getData();
        },
        getData() {
            var poCodeIds = []
            if (Array.isArray(this.poCodes)) {
                this.poCodes.forEach(e => { if (e.id) poCodeIds.push(e.id) });
            }
            var params = {
                limit: this.table.length,
                page: this.table.page,
                query: this.table.search,
                order: this.table.order,
                sort: this.table.sort,
                warehouse_ids: this.sourceWarehouseIds,
                supplier_ids: this.supplierIds,
                ids: this.poIds,
                po_type_id: this.poTypeId,
                status: this.status,
            }
            // if (this.rangeDate) {
            //     params.start_date = this.rangeDate.startDate
            //     params.end_date = this.rangeDate.endDate
            // }
            this.$vs.loading();
            this.$http
                .get(this.$store.state.inbound.purchaseOrder.baseUrlPath + "/receive", {
                    params: params,
                })
                .then((resp) => {
                    if (resp.code == 200) {
                        this.checkedAll = false;
                        this.checked = [];
                        this.selectedRouteAssignmentToAssign = [];

                        this.table.total = resp.data.total_rows;
                        this.table.totalPage = resp.data.total_pages;
                        this.table.totalSearch = resp.data.total_record_search;
                        this.table.length = resp.data.limit;
                        var rows = resp.data.rows;
                        if (rows) {
                        console.log(rows)
                            rows.forEach((row, ir) => {
                                if (row.purchase_receive_lines) {
                                    const prline = new Map();
                                    const huline = new Map();
                                    const damaged_line = new Map();
                                    const passed_line = new Map();
                                    const expired_line = new Map();
                                    var prCount = 0;
                                    var huCount = 0;                                    
                                    row.purchase_order_lines.forEach(item => {
                                        huCount++;
                                        if (huline.has(item.item_unit)) {
                                            huline.set(item.item_unit, Number(huline.get(item.item_unit)) + Number(item.quantity))
                                        } else {
                                            huline.set(item.item_unit, item.quantity);
                                        }
                                    });
                                    row.purchase_receive_lines.forEach(item => {
                                        var unit_name = item.item_unit.unit ? item.item_unit.unit.name : ""
                                        if (Number(item.quantity) != 0) { 
                                            if (item.is_damaged == 5) {
                                                prCount++;
                                                if (prline.has(unit_name)) {
                                                    prline.set(unit_name, Number(prline.get(unit_name)) + Number(item.quantity))
                                                } else {
                                                    prline.set(unit_name, item.quantity);
                                                }
                                            } else if (item.is_damaged == 3) {
                                                // huCount++;
                                                // if (huline.has(unit_name)) {
                                                //     huline.set(unit_name, Number(huline.get(unit_name)) + Number(item.quantity))
                                                // } else {
                                                //     huline.set(unit_name, item.quantity);
                                                // }
                                            } else if (item.is_damaged == 0) {
                                                if (passed_line.has(unit_name)) {
                                                    passed_line.set(unit_name, Number(passed_line.get(unit_name)) + Number(item.quantity))
                                                } else {
                                                    passed_line.set(unit_name, item.quantity);
                                                }
                                            } else if (item.is_damaged == 1) {
                                                if (damaged_line.has(unit_name)) {
                                                    damaged_line.set(unit_name, Number(damaged_line.get(unit_name)) + Number(item.quantity))
                                                } else {
                                                    damaged_line.set(unit_name, item.quantity);
                                                }
                                            } else if (item.is_damaged == 2) {
                                                if (expired_line.has(unit_name)) {
                                                    expired_line.set(unit_name, Number(expired_line.get(unit_name)) + Number(item.quantity))
                                                } else {
                                                    expired_line.set(unit_name, item.quantity);
                                                }
                                            }
                                        }
                                    });
                                    rows[ir].pr_lines = Object.fromEntries(prline);
                                    rows[ir].hu_lines = Object.fromEntries(huline);
                                    rows[ir].passed_line = Object.fromEntries(passed_line);
                                    rows[ir].damaged_line = Object.fromEntries(damaged_line);
                                    rows[ir].expired_line = Object.fromEntries(expired_line);
                                    rows[ir].pr_count = prCount;
                                    rows[ir].hu_count = huCount;
                                    rows[ir].status_checked = false;
                                }
                            });
                        }
                        this.table.data = rows;
                        this.setStartEnd();
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                });
        },
        setStartEnd() {
            let valStart =
                this.table.length * this.table.page - this.table.length + 1;

            if (valStart > this.table.total) {
                valStart = 1;
            }
            if (this.table.total == 0) {
                valStart = 0;
            }
            let valEnd = this.table.length * this.table.page;

            if (valEnd > this.table.total) {
                valEnd = this.table.total;
            }

            if (
                this.table.totalSearch < this.table.total &&
                this.table.search != ""
            ) {
                valEnd = this.table.totalSearch;
            }

            this.table.start = valStart;
            this.table.end = valEnd;
        },
        checkConfirm(id, index){
            this.table.data[index].status_checked = !this.table.data[index].status_checked
            if (this.table.data[index].status_putaway == 1) {
                this.addOrRemoveID(this.prIDs, id)
            }
            
            // console.log("id selected ", this.prIDs)
        },
        addOrRemoveID(array, item){
            let statusAdd = true
            for(var i in array){
                if(array[i]==item){
                    array.splice(i,1);
                    // console.log("remove ", item)
                    statusAdd = false
                    break;
                }
            }

            if (statusAdd) {
                // console.log("add ", item)
                array.push(item)
            }
        },
        confirmAll() {
            let _this = this
            let st = this.statusConfirmAll
            this.statusConfirmAll = !st
            // console.log("status confirm all ", this.statusConfirmAll)
            this.prIDs = []
            this.table.data.forEach(function (element, index) {
                if (_this.statusConfirmAll) {
                    _this.table.data[index].status_checked = true
                    if (element.status_putaway == 1) {
                        _this.addOrRemoveID(_this.prIDs, element.id)
                    }
                } else {
                    _this.table.data[index].status_checked = false
                }
            });
        },
        handleMultiConfirm(){
            console.log("id selected ", this.prIDs)
            if (this.prIDs.length == 0) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "Please select at least 1 data",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
            } else {
                this.$vs.dialog({
                    type: "confirm",
                    title: `Confirm`,
                    text: "Please confirm to complete this data",
                    accept: () => {
                        this.$vs.loading();
                        var formData = new FormData();
                        formData.append("pr_ids", JSON.stringify(this.prIDs));
                        this.$http.post(`${this.$store.state.inbound.purchaseOrder.baseUrlPath}/status/completed`, formData)
                        .then((resp) => {
                            this.$vs.loading.close();
                            if (resp.code == 200) {
                                this.$vs.notify({
                                    color: "success",
                                    title: "Success",
                                    text: "The data has been completed successfully",
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-x-circle",
                                });
                                this.prIDs = []
                                this.getData();
                            } else {
                                if (resp.message == "This trx is processing by another user. Please reload this page!") {
                                    this.$vs.dialog({
                                        type: "confirm",
                                        color: "warning",
                                        title: `Information`,
                                        text: resp.message,
                                        accept: this.getData,
                                        acceptText: `OK`,
                                    });
                                } else {
                                    this.$vs.notify({
                                        color: "danger",
                                        title: "Error",
                                        text: resp.message,
                                        position: "top-right",
                                        iconPack: "feather",
                                        icon: "icon-x-circle",
                                    });
                                }
                            }
                        });
                    },
                });
            }
        },
        handleCompleted(id) {
            this.deleteId = id;
            this.$vs.dialog({
                type: "confirm",
                title: `Confirm`,
                text: "Please confirm to complete this data",
                accept: () => {
                    this.$vs.loading();
                    this.$http.patch(`${this.$store.state.inbound.purchaseOrder.baseUrlPath}/${id}/status/completed`)
                    .then((resp) => {
                        this.$vs.loading.close();
                        if (resp.code == 200) {
                            this.$vs.notify({
                                color: "success",
                                title: "Success",
                                text: "The data has been completed successfully",
                                position: "top-right",
                                iconPack: "feather",
                                icon: "icon-x-circle",
                            });
                            this.getData();
                        } else {
                            if (resp.message == "This trx is processing by another user. Please reload this page!") {
                                this.$vs.dialog({
                                    type: "confirm",
                                    color: "warning",
                                    title: `Information`,
                                    text: resp.message,
                                    accept: this.getData,
                                    acceptText: `OK`,
                                });
                            } else {
                                this.$vs.notify({
                                    color: "danger",
                                    title: "Error",
                                    text: resp.message,
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-x-circle",
                                });
                            }
                        }
                    });
                },
            });
        },
        handleCreateCheck(prID) {
            this.$router.push({
                name: this.$store.state.inbound.purchaseOrder.baseRouterName + ".create-put-away",
                params: { id: prID },
            });
        },
        handlePrint(id) {
            this.$router.push({
                name: this.$store.state.inbound.purchaseOrder.baseRouterName + ".print-put-away",
                params: { id: id },
            });
        },
        handlePrintPdf(id) {
            console.log("do print pdf");
            this.$vs.loading();
            this.$http
            .get(`${this.$store.state.inbound.purchaseOrder.baseUrlPath}/put-away/${id}`)
            .then((resp) => {
                const doc = new jsPDF({
                    orientation: 'l',
                    // margin: {top: 60},
                });
                const lineHeight = 1.5;
                // let fontSize = 15;
                // doc.setFontSize(fontSize, "bold"); //.setFontStyle("bold");
                // doc.text("BERITA ACARA PENERIMAAN BARANG ", 150, 10, { align: "center" }); //(text,x,y)
                // doc.text(resp.data.code, 150, 15, { align: "center" }); //(text,x,y)
               
                let header = []
                let header1 = []
                header.push(
                    [
                        "Nama Cabang : " + resp.data.warehouse.code + " " + resp.data.warehouse.name,
                    ],
                    [
                        "Pada Hari/Tanggal : " + moment(resp.data.date).format("YYYY-MM-DD"),
                    ],
                    [
                        "Telah dilakukan pemeriksaan terhadap kiriman dari : " + resp.data.supplier_code + " " + resp.data.supplier_name,
                    ],
                    [
                        "Berikut detail yang telah kami terima :",
                    ]
                )
                var po_code_trim = resp.data.po_code
                const noPo = resp.data.po_code.slice(-3)
                if (noPo.includes("-")) {
                    po_code_trim = resp.data.po_code.slice(0, -3)
                }
                header1.push(
                    [
                        "Nama Transporter",
                        ": ",
                        "No. PO",
                        ": " + po_code_trim
                    ],
                    [
                        "Nama Pengemudi",
                        ": ",
                        "No. Surat Jalan",
                        ": " + resp.data.delivery_order_number
                    ],
                    [
                        "No. Polisi Kendaraan",
                        ": ",
                        "Tanggal Surat Jalan",
                        ": " + this.formatDate(resp.data.dn_date)
                    ]
                )

                autoTable(doc, {
                    startY: 17,
                    head: [],
                    body: header,
                    theme: 'plain',
                    margin: {right:10,left:10},
                    styles: { lineHeight: lineHeight, cellPadding: 0 },
                    
                    columnStyles: {
                        0: {
                            fontSize: '9',
                            // cellWidth: 30
                            // fontStyle: 'bold'
                        }
                    }
                });
                
                autoTable(doc, {
                    startY: 37,
                    head: [],
                    body: header1,
                    theme: 'plain',
                    margin: {right:10,left:10},
                    styles: { lineHeight: lineHeight, cellPadding: 0 },
                    
                    columnStyles: {
                        0: {
                            fontSize: '9',
                            cellWidth: 35
                            // fontStyle: 'bold'
                        },
                        1: {
                            fontSize: '9',
                            cellWidth: 90
                            // fontStyle: 'bold'
                        },
                        2: {
                            fontSize: '9',
                            cellWidth: 35
                            // fontStyle: 'bold'
                        },
                        3: {
                            fontSize: '9',
                            // fontStyle: 'bold'
                        }
                    }
                });
                
                // const head = [
                //     ["No.", "Kode Item", "Nama Barang", "Jumlah", "Hasil Pemeriksaan Cabang", "Keterangan"],
                // ];
                let line = [];
                let y = 32;
                line.push([
                    { content: "No. ", rowSpan: 2},
                    { content: "Kode Item", rowSpan: 2 },
                    { content: "Nama Barang", rowSpan: 2 },
                    { content: "Jumlah", rowSpan: 2 },
                    { content: "Hasil Pemeriksaan Cabang", colSpan: 6 },
                    { content: "Keterangan", rowSpan: 2 },
                ]);
                line.push([
                    { content: "Baik", fontSize: '9', styles: { halign: 'center' }},
                    { content: "Rusak", fontSize: '9', styles: { halign: 'center' }},
                    { content: "  +  ", fontSize: '9', styles: { halign: 'center' }},
                    { content: "  -  ", fontSize: '9', styles: { halign: 'center' }},
                    { content: "No. Batch", fontSize: '9', styles: { halign: 'center' }},
                    { content: "Expired", fontSize: '9', styles: { halign: 'center' }},
                ]);

                let skuUnique = []
                let key = []
                resp.data.putaway_dto.map((v, i) => {
                    console.log("&&&&&&&&&&")
                    console.log(v.is_damaged)
                    if (skuUnique[v.internal_sku_code+""+v.hu_name+""+v.batch]) { // jika ada
                        if (v.is_damaged == 0) {
                            console.log("quantity pass 2")
                            skuUnique[v.internal_sku_code+""+v.hu_name+""+v.batch].qty_pass += v.check_quantity
                        } else {
                            skuUnique[v.internal_sku_code+""+v.hu_name+""+v.batch].qty_damage += v.check_quantity
                        }
                        skuUnique[v.internal_sku_code+""+v.hu_name+""+v.batch].qty += v.check_quantity
                    } else { // jika tidak ada
                        let qty_pass = 0
                        let qty_damage = 0
                        if (v.is_damaged == 0) {
                            console.log("quantity pass 1")
                            qty_pass = v.check_quantity
                        } else {
                            qty_damage = v.check_quantity
                        }
                        skuUnique[v.internal_sku_code+""+v.hu_name+""+v.batch] = {
                            "sku_code": v.internal_sku_code,
                            "item_name": v.item_name,
                            "unit_name": v.hu_name,
                            "qty": qty_pass+qty_damage,
                            "qty_pass": qty_pass,
                            "qty_damage": qty_damage,
                            "batch": v.batch,
                            "expired_date": v.expired_date,
                            "keterangan": ""
                        }
                        key.push(v.internal_sku_code+""+v.hu_name+""+v.batch);
                    }
                });

                console.log("========")
                console.log(skuUnique)

                for (let i = 0; i < key.length; i++) {
                    line.push([
                        i + 1,
                        skuUnique[key[i]].sku_code,
                        skuUnique[key[i]].item_name,
                        skuUnique[key[i]].qty + " " + skuUnique[key[i]].unit_name,
                        skuUnique[key[i]].qty_pass + " " + skuUnique[key[i]].unit_name,
                        skuUnique[key[i]].qty_damage + " " + skuUnique[key[i]].unit_name,
                        "","",
                        skuUnique[key[i]].batch,
                        skuUnique[key[i]].expired_date,
                        ""
                    ]);
                    // for (let j = 0; j < 20; j++) {
                    //     line.push([
                    //         i + 1,
                    //         skuUnique[key[i]].sku_code,
                    //         skuUnique[key[i]].item_name,
                    //         skuUnique[key[i]].qty + " " + skuUnique[key[i]].unit_name,
                    //         skuUnique[key[i]].qty_pass + " " + skuUnique[key[i]].unit_name,
                    //         skuUnique[key[i]].qty_damage + " " + skuUnique[key[i]].unit_name,
                    //         "","",
                    //         skuUnique[key[i]].batch,
                    //         skuUnique[key[i]].expired_date,
                    //         ""
                    //     ]);
                    // }
                    y = y + 20;
                }

                autoTable(doc, {
                    startY: 50,
                    head: [],
                    body: line,
                    margin: {right:10,left:10},
                    theme: "grid",
                    pageBreak: 'auto',
                    drawCell: function (cell, data) {
                        console.log(cell, data.column, "data.column");
                        cell.styles.halign = "center";
                        cell.styles.fontSize = '9';
                    },
                    didParseCell: function (cell, data) {
                    },
                    styles: {
                        halign: 'center',
                        // valign: 'center',
                        fontSize: '9'
                    },
                    columnStyles: {
                        0: {
                            halign: 'center',
                            // valign: 'center',
                            fontSize: '9',
                        },
                        1: {
                            halign: 'center',
                            // valign: 'center',
                            fontSize: '9',
                        },
                        2: {
                            halign: 'center',
                            // valign: 'center',
                            fontSize: '9',
                        },
                        3: {
                            halign: 'center',
                            // valign: 'center',
                            fontSize: '9',
                        },
                        4: {
                            halign: 'center',
                            // valign: 'center',
                            fontSize: '9',
                        },
                        5: {
                            halign: 'center',
                            // valign: 'center',
                            fontSize: '9',
                        },
                    }
                });

                let footer = []
                let footer1 = []
                footer.push(
                    [
                        "Demikian Berita Acara ini kami buat dengan sebenar-benarnya.",
                        "",
                        "",
                    ]
                )
                footer.push(["", "", ""]);
                footer.push(["", "", ""]);
                footer1.push(                    
                    [
                        "Dikirim Oleh,",
                        "Diterima Oleh,",
                        "Diketahui Oleh,",
                    ],
                    [
                        "Transporter/Pengirim",
                        "WHS SPV/PIC Logistik",
                        "BLC/OS/APJ",
                    ],
                    [
                        "", "", "",
                    ],
                    [
                        "", "", "",
                    ],
                    [
                        "", "", "",
                    ],
                    [
                        "", "", "",
                    ],
                    [
                        "(                                 )",
                        "(                                 )",
                        "(                                 )",
                    ],
                    [
                        "Tanggal:                        ",
                        "Tanggal:                        ",
                        "Tanggal:                        ",
                    ],
                )
                // Merge the two arrays
                let combinedFooter = footer.concat(footer1);
                
                autoTable(doc, {
                    // startY: 55,
                    head: [],
                    body: combinedFooter,
                    margin: {right:10,left:10},
                    styles: { lineHeight: lineHeight, cellPadding: 0 },
                    theme: "plain",
                    columnStyles: {
                        0: {
                            halign: 'center',
                            fontSize: '9',
                            cellWidth: 50
                        },
                        1: {
                            halign: 'center',
                            fontSize: '9',
                            cellWidth: 50
                        },
                        2: {
                            halign: 'center',
                            fontSize: '9',
                            cellWidth: 50
                        }
                    },
                    didParseCell: function (data) {
                        // Apply different cellWidth for the first footer
                        if (data.row.index === 0) {
                            data.cell.styles.halign = 'left';
                            data.cell.styles.fontSize = '9';
                            data.cell.styles.cellWidth = 100;
                        }
                    },
                    pageBreak: "avoid"
                });

                // let footer2 = []
                // footer2.push(
                //     [
                //         "No. Dokumen: " + resp.data.code,
                //         "Tanggal Efektif: " + moment().format("DD MMM YYYY")
                //     ],
                //     [
                //         "No. Rev: -",
                //         ""
                //     ]
                // )
                // autoTable(doc, {
                //     // startY: 55,
                //     head: [],
                //     body: footer2,
                //     margin: {right:10,left:10},
                //     theme: "plain",
                //     columnStyles: {
                //         0: {
                //             halign: 'left',
                //             fontSize: '9',
                //             cellWidth: 200
                //         },
                //         1: {
                //             halign: 'left',
                //             fontSize: '9',
                //         }
                //     }
                // });

                const pageCount = doc.internal.getNumberOfPages();
                for(var i = 1; i <= pageCount; i++) {
                    doc.setFontSize(15, "bold"); //.setFontStyle("bold");
                    doc.text("BERITA ACARA PENERIMAAN BARANG ", 150, 8, { align: "center" }); //(text,x,y)
                    doc.setFontSize(13, "bold");
                    doc.text(resp.data.code, 150, 13, { align: "center" }); //(text,x,y)

                    // Go to page i
                    doc.setPage(i);
                    doc.setFontSize(10);
                    doc.text('Halaman ' + String(i) + ' dari ' + String(pageCount),300-20,230-25,null,null,"right");
                }

                
                
                doc.save(
                    "Inbound-GR-" + resp.data.code + ".pdf"
                );

                // console.log(params, "params");
                // this.$http
                //     .post(
                //     "/api/v1/purchase-payment-request/print/" + selected.ID,
                //     params
                //     )
                //     .then((r) => {
                //     this.reloadData(this.params);
                //     })
                //     .catch((e) => {
                //         this.reloadData(this.params);
                //     });
                this.$vs.loading.close();
            })
            .catch((e) => {
            this.$vs.loading.close();
            });
        },
        utcToLocal(val) {
            if (val) {
                return moment(val)
                    .add(-7, "h")
                    .utc()
                    .local()
                    .format("DD MMM YYYY HH:mm:ss");
            }
        },
    },
    mounted() { },
    watch: {
        draw() {
            this.getData();
        },
    },
    computed: {
        setPage: {
            get() {
                return 1;
            },
            set(val) {
                if (this.isGetData){
                    this.handleChangePage(val);
                }
            },
        },
        formatDate: () => {
            return (val) => {
                const momentDate = moment.utc(val);
                return momentDate.format('YYYY-MM-DD');
            };
        },
        formatLocalNumber: () => {
            return (val) => {
                return Number(val).toLocaleString("id-ID");
            };
        },
    },
};
</script>
<style>
.vs-dialog-warning .vs-dialog footer .vs-button-primary{
    display: none;
}
</style>