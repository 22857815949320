<template>
    <div>
        <vs-table search stripe border description :sst="true" :data="table.data" :max-items="table.length"
            :total="table.total" @search="handleSearch" @change-page="handleChangePage" @sort="handleSort">
            <template slot="header">
                <vs-dropdown vs-trigger-click class="cursor-pointer">
                    <div
                        class="flex items-center justify-between p-3 font-medium border border-solid rounded-full cursor-pointer d-theme-border-grey-light d-theme-dark-bg">
                        <span class="mr-2">{{ this.table.start }} - {{ this.table.end }} of
                            {{ this.table.total }}</span>
                        <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                    </div>
                    <vs-dropdown-menu>
                        <vs-dropdown-item v-for="item in table.limits" :key="item" @click="handleChangelength(item)">
                            <span>{{ item }}</span>
                        </vs-dropdown-item>
                    </vs-dropdown-menu>
                </vs-dropdown>
            </template>
            <template slot="thead">
                <vs-th>Action</vs-th>
                <vs-th sort-key="">Supplier Data</vs-th>
                <vs-th sort-key="">PO Data</vs-th>
                <vs-th sort-key="">PO Type</vs-th>
                <vs-th sort-key="">ETA</vs-th>
                <vs-th sort-key=""># of SKU</vs-th>
                <vs-th sort-key="">Total Qty {HU/Qty}</vs-th>
                <vs-th sort-key=""># of SKU</vs-th>
                <vs-th sort-key="">Total Qty {HU/Qty}</vs-th>
                <vs-th sort-key="">Status</vs-th>
            </template>
            <template slot-scope="{data}">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td>
                        <div class="flex vx-input-group">
                            <vs-button size="small" color="warning" icon-pack="feather" icon="icon-edit" title="Edit"
                                style="margin-right: 5px" @click="handleCreate(tr.po_id)" />
                            <!-- <vs-button size="small" v-if="tr.id && tr.id > 0" color="grey" icon-pack="feather"
                                icon="icon-printer" title="Print" style="margin-right: 5px"
                                @click="handlePrintPdf(tr.id)" /> -->
                            <vs-button v-if="tr.id && tr.id > 0" size="small" color="success" icon-pack="feather"
                                icon="icon-check" title="Create GR" @click="handleCreateGR(tr.id)" />
                        </div>
                    </vs-td>
                    <vs-td>
                        <div class="flex flex-col content-center">
                            <div>Code : {{ tr.supplier_code }}</div>
                            <div>Name : {{ tr.supplier_name }}</div>
                            <div>City : {{ tr.supplier_city }}</div>
                        </div>
                    </vs-td>
                    <vs-td>
                        <div class="flex flex-col content-center">
                            <div>Warehouse : {{ tr.warehouse.name }}</div>
                            <div>Code : {{ tr.po_code }}</div>
                            <div>PayTerm : {{ tr.payment_term_name }}</div>
                            <div>PostDate : {{ formatDate(tr.purchase_posting_date) }}</div>
                            <div>Date : {{ formatDate(tr.purchase_date) }}</div>
                        </div>
                    </vs-td>
                    <vs-td>
                        <div class="flex flex-col content-center">
                            <div>{{ tr.purchase_type_name }}</div>
                        </div>
                    </vs-td>
                    <vs-td>
                        <div class="flex flex-col content-center">
                            <div>{{ formatDate(tr.eta) }}</div>
                        </div>
                    </vs-td>
                    <vs-td>
                        <div class="flex flex-col content-center">
                            <div>{{ tr.purchase_order_lines.length }}</div>
                        </div>
                    </vs-td>
                    <vs-td>
                        <div v-for="(qty, hu) in tr.purchase_order_hus" :key="hu" class="flex flex-col content-center">
                            <span>{{ qty }} {{ hu }}</span>
                        </div>
                    </vs-td>
                    <vs-td>
                        <div class="flex flex-col content-center">
                            <div v-if="tr.pr_count>0">{{ tr.pr_count }}</div>
                        </div>
                    </vs-td>
                    <vs-td>
                        <div v-if="tr.purchase_receive_lines">
                            <div v-for="(qty, hu) in tr.purchase_receive_hus" :key="hu" class="flex flex-col content-center">
                                <span>{{ qty }} {{ hu }}</span>
                            </div>
                        </div>
                    </vs-td>
                    <vs-td>
                        <div class="flex flex-col content-center">
                            <div>[{{ tr.is_partial == "1" ? 'Part' : 'Main' }}] {{ tr.po_status }}</div>
                        </div>
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <vs-pagination style="padding-top: 5px" :total="table.totalPage" v-model="setPage" />

        <vs-prompt
            @close="activePrmp=false; getData()"
            @accept="getData()"
            :active.sync="activePrmp"
            :title="'Information'">
            <div class="con-exemple-prompt">
                <p>{{prmpMsg}}</p>
            </div>
        </vs-prompt>
    </div>
</template>
<script>
import moment from "moment";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
export default {
    components: {},
    props: {
        draw: {
            type: Number,
        },
        sourceWarehouseIds: {
            type: String,
        },
        poIds: {
            type: String,
        },
        supplierIds: {
            type: String,
        },
        isGetData: {
            type: Boolean,
        },
        // transactionDate: {
        //     type: Date,
        //     default: null,
        // },
        // rangeDate: {
        //     default: null,
        // },
        status: {
            type: String,
        },
    },
    data() {
        return {
            deleteId: null,
            table: this.tableDefaultState(),
            checkedAll: false,
            checked: [],
            selectedRouteAssignmentToAssign: [],
            timeOuts: null,
            activePrmp: false,
            prmpMsg: "",
        };
    },
    methods: {
        tableDefaultState() {
            return {
                data: [],
                length: 10,
                page: 1,
                search: "",
                order: "eta",
                sort: "desc",
                total: 0,
                totalPage: 1,
                totalSearch: 0,
                limits: [10, 25, 50, 100, "All"],
                start: 1,
                end: 0,
            };
        },
        handleSearch(searching) {
            this.table.search = searching;
            this.table.page = 1;
            if (this.timeOuts) { clearTimeout(this.timeOuts) }
            this.timeOuts = setTimeout(this.getData, 900);
        },
        handleChangePage(page) {
            this.table.page = page;
            this.getData();
        },
        handleSort(key, active) {
            this.table.order = key;
            this.table.sort = active;
            this.getData();
        },
        handleChangelength(val) {
            this.table.length = val == "All" ? this.table.total : val;
            this.table.page = 1;
            this.getData();
        },
        getData() {
            var params = {
                limit: this.table.length,
                page: this.table.page,
                query: this.table.search,
                order: this.table.order,
                sort: this.table.sort,
                warehouse_ids: this.sourceWarehouseIds,
                supplier_ids: this.supplierIds,
                ids: this.poIds,
                status: this.status,
            }
            // if (this.rangeDate) {
            //     params.start_date = this.rangeDate.startDate
            //     params.end_date = this.rangeDate.endDate
            // }
            this.$vs.loading();
            this.$http
                .get(this.$store.state.inbound.purchaseOrder.baseUrlPath + "/receive", {
                    params: params,
                })
                .then((resp) => {
                    if (resp.code == 200) {
                        this.checkedAll = false;
                        this.checked = [];
                        this.selectedRouteAssignmentToAssign = [];

                        this.table.total = resp.data.total_rows;
                        this.table.totalPage = resp.data.total_pages;
                        this.table.totalSearch = resp.data.total_record_search;
                        this.table.length = resp.data.limit;
                        var rows = resp.data.rows;
                        if (rows) {
                            console.log(rows)
                            rows.forEach((row, ir) => {
                                const po = new Map();
                                row.purchase_order_lines.forEach(item => {                                    
                                    if (po.has(item.item_unit)) {
                                        po.set(item.item_unit, Number(po.get(item.item_unit)) + Number(item.quantity))
                                    } else {
                                        po.set(item.item_unit, item.quantity);
                                    }
                                });
                                rows[ir].purchase_order_hus = Object.fromEntries(po);

                                rows[ir].pr_count=0;
                                if (row.purchase_receive_lines) {
                                    const pr = new Map();
                                    row.purchase_receive_lines.forEach(item => {
                                        if (Number(item.quantity) != 0) {
                                            if (item.is_damaged == 0 || item.is_damaged == 1 || item.is_damaged == 2) {
                                                rows[ir].pr_count++;
                                                var uname = item.item_unit.unit ? item.item_unit.unit.name:""
                                                if (pr.has(uname)) {
                                                    pr.set(uname, Number(pr.get(uname)) + Number(item.quantity))
                                                } else {
                                                    pr.set(uname, item.quantity);
                                                }
                                                // pr.push({unit: uname, qty: item.quantity})
                                            }
                                        }
                                    });
                                    rows[ir].purchase_receive_hus = Object.fromEntries(pr);
                                }
                            });
                        }
                        this.table.data = rows;
                        this.setStartEnd();
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                });
        },
        setStartEnd() {
            let valStart =
                this.table.length * this.table.page - this.table.length + 1;

            if (valStart > this.table.total) {
                valStart = 1;
            }
            if (this.table.total == 0) {
                valStart = 0;
            }
            let valEnd = this.table.length * this.table.page;

            if (valEnd > this.table.total) {
                valEnd = this.table.total;
            }

            if (
                this.table.totalSearch < this.table.total &&
                this.table.search != ""
            ) {
                valEnd = this.table.totalSearch;
            }

            this.table.start = valStart;
            this.table.end = valEnd;
        },
        handleCreateGR(id) {
            this.deleteId = id;
            this.$vs.dialog({
                type: "confirm",
                title: `Confirm`,
                text: "Please confirm to receive and create GR Document.",
                accept: () => {
                    this.$vs.loading();
                    this.$http.patch(`${this.$store.state.inbound.purchaseOrder.baseUrlPath}/${id}/status/confirmedcheck`)
                        .then((resp) => {
                            this.$vs.loading.close();
                            if (resp.code == 200) {
                                this.$vs.dialog({
                                    type: "confirm",
                                    color: "success",
                                    title: `Information`,
                                    text: "GR with code "+resp.data.code+" Successfully Created.",
                                    accept: this.getData,
                                    acceptText: `OK`,
                                });
                            } else {
                                if (resp.message == "This trx is processing by another user. Please reload this page!") {
                                    this.$vs.dialog({
                                        type: "confirm",
                                        color: "warning",
                                        title: `Information`,
                                        text: resp.message,
                                        accept: this.getData,
                                        acceptText: `OK`,
                                    });
                                } else {
                                    this.$vs.notify({
                                        color: "danger",
                                        title: "Error",
                                        text: resp.message,
                                        position: "top-right",
                                        iconPack: "feather",
                                        icon: "icon-x-circle",
                                    });
                                }
                            }
                        });
                },
            });
        },
        handleCreate(poID) {
            this.$router.push({
                name: this.$store.state.inbound.purchaseOrder.baseRouterName + ".create-receive",
                params: { id: poID },
            });
        },
        handlePrint(id) {
            this.$router.push({
                name: this.$store.state.inbound.purchaseOrder.baseRouterName + ".print-receive",
                params: { id: id },
            });
        },
        handlePrintPdf(id) {
            console.log("do print pdf");
            this.$vs.loading();
            this.$http
            .get(`${this.$store.state.inbound.purchaseOrder.baseUrlPath}/put-away/${id}`)
            .then((resp) => {
                const doc = new jsPDF({
                    orientation: 'l'                    
                });
                const lineHeight = 1.5;
                // let fontSize = 15;
                // doc.setFontSize(fontSize, "bold"); //.setFontStyle("bold");
                // doc.text("BERITA ACARA PENERIMAAN BARANG ", 150, 10, { align: "center" }); //(text,x,y)
                // doc.text(resp.data.code, 150, 15, { align: "center" }); //(text,x,y)
               
                let header = []
                let header1 = []
                header.push(
                    [
                        {content: "Nama Cabang : " + resp.data.warehouse.code + " " + resp.data.warehouse.name, styles: {padding: {top:0, bottom: 0}}}
                    ],
                    [
                        "Pada Hari/Tanggal : " + moment(resp.data.date).format("YYYY-MM-DD"),
                    ],
                    [
                        "Telah dilakukan pemeriksaan terhadap kiriman dari : " + resp.data.supplier_code + " " + resp.data.supplier_name,
                    ],
                    [
                        "Berikut detail yang telah kami terima :",
                    ]
                )
                var po_code_trim = resp.data.po_code
                const noPo = resp.data.po_code.slice(-3)
                if (noPo.includes("-")) {
                    po_code_trim = resp.data.po_code.slice(0, -3)
                }
                header1.push(
                    [
                        "Nama Transporter",
                        ": ",
                        "No. PO",
                        ": " + po_code_trim
                    ],
                    [
                        "Nama Pengemudi",
                        ": ",
                        "No. Surat Jalan",
                        ": " + resp.data.delivery_order_number
                    ],
                    [
                        "No. Polisi Kendaraan",
                        ": ",
                        "Tanggal Surat Jalan",
                        ": " + this.formatDate(resp.data.dn_date)
                    ]
                )

                autoTable(doc, {
                    startY: 17,
                    head: [],
                    body: header,
                    theme: 'plain', //'grid',
                    margin: {right:10,left:10},
                    styles: { lineHeight: lineHeight, cellPadding: 0 },
                    // padding: {top:0,bottom:0},
                    
                    columnStyles: {
                        0: {
                            fontSize: '9',
                            // padding: {top:0,bottom:0},
                            // minCellHeight: 1,
                            // cellHeight: 1,
                            // cellWidth: 'wrap'
                            // cellWidth: 30
                            // fontStyle: 'bold'
                        }
                    }
                });
                
                autoTable(doc, {
                    startY: 37,
                    head: [],
                    body: header1,
                    theme: 'plain',
                    margin: {right:10,left:10},
                    styles: { lineHeight: lineHeight, cellPadding: 0 },
                    
                    columnStyles: {
                        0: {
                            fontSize: '9',
                            cellWidth: 35
                            // fontStyle: 'bold'
                        },
                        1: {
                            fontSize: '9',
                            cellWidth: 90
                            // fontStyle: 'bold'
                        },
                        2: {
                            fontSize: '9',
                            cellWidth: 35
                            // fontStyle: 'bold'
                        },
                        3: {
                            fontSize: '9',
                            // fontStyle: 'bold'
                        }
                    }
                });
                
                // const head = [
                //     ["No.", "Kode Item", "Nama Barang", "Jumlah", "Hasil Pemeriksaan Cabang", "Keterangan"],
                // ];
                let line = [];
                let y = 32;
                line.push([
                    { content: "No. ", rowSpan: 2},
                    { content: "Kode Item", rowSpan: 2 },
                    { content: "Nama Barang", rowSpan: 2 },
                    { content: "Jumlah", rowSpan: 2 },
                    { content: "Hasil Pemeriksaan Cabang", colSpan: 6 },
                    { content: "Keterangan", rowSpan: 2 },
                ]);
                line.push([
                    { content: "Baik", fontSize: '9', styles: { halign: 'center' }},
                    { content: "Rusak", fontSize: '9', styles: { halign: 'center' }},
                    { content: "  +  ", fontSize: '9', styles: { halign: 'center' }},
                    { content: "  -  ", fontSize: '9', styles: { halign: 'center' }},
                    { content: "No. Batch", fontSize: '9', styles: { halign: 'center' }},
                    { content: "Expired", fontSize: '9', styles: { halign: 'center' }},
                ]);

                let skuUnique = []
                let key = []
                resp.data.putaway_dto.map((v, i) => {
                    console.log("&&&&&&&&&&")
                    console.log(v.is_damaged)
                    if (skuUnique[v.internal_sku_code+""+v.hu_name+""+v.batch]) { // jika ada
                        if (v.is_damaged == 0) {
                            console.log("quantity pass 2")
                            skuUnique[v.internal_sku_code+""+v.hu_name+""+v.batch].qty_pass += v.check_quantity
                        } else {
                            skuUnique[v.internal_sku_code+""+v.hu_name+""+v.batch].qty_damage += v.check_quantity
                        }
                        skuUnique[v.internal_sku_code+""+v.hu_name+""+v.batch].qty += v.check_quantity
                    } else { // jika tidak ada
                        let qty_pass = 0
                        let qty_damage = 0
                        if (v.is_damaged == 0) {
                            console.log("quantity pass 1")
                            qty_pass = v.check_quantity
                        } else {
                            qty_damage = v.check_quantity
                        }
                        skuUnique[v.internal_sku_code+""+v.hu_name+""+v.batch] = {
                            "sku_code": v.internal_sku_code,
                            "item_name": v.item_name,
                            "unit_name": v.hu_name,
                            "qty": qty_pass+qty_damage,
                            "qty_pass": qty_pass,
                            "qty_damage": qty_damage,
                            "batch": v.batch,
                            "expired_date": v.expired_date,
                            "keterangan": ""
                        }
                        key.push(v.internal_sku_code+""+v.hu_name+""+v.batch);
                    }
                });

                console.log("========")
                console.log(skuUnique)

                for (let i = 0; i < key.length; i++) {
                    line.push([
                        i + 1,
                        {content: skuUnique[key[i]].sku_code, styles: { halign: 'center' }},
                        skuUnique[key[i]].item_name,
                        skuUnique[key[i]].qty + " " + skuUnique[key[i]].unit_name,
                        skuUnique[key[i]].qty_pass + " " + skuUnique[key[i]].unit_name,
                        skuUnique[key[i]].qty_damage + " " + skuUnique[key[i]].unit_name,
                        "",
                        "",
                        skuUnique[key[i]].batch,
                        skuUnique[key[i]].expired_date,
                        ""
                    ]);
                    y = y + 20;
                }

                autoTable(doc, {
                    startY: 50,
                    head: [],
                    body: line,
                    margin: {right:10,left:10},
                    theme: "grid",
                    drawCell: function (cell, data) {
                        console.log(cell, data.column, "data.column");
                        cell.styles.halign = "center";
                        cell.styles.fontSize = '9';
                    },
                    didParseCell: function (cell, data) {
                    },
                    styles: {
                        halign: 'center',
                        // valign: 'center',
                        fontSize: '9',
                    },
                    columnStyles: {
                        0: {
                            halign: 'center',
                            // valign: 'center',
                            fontSize: '9',
                        },
                        1: {
                            halign: 'center',
                            // valign: 'center',
                            fontSize: '9',
                        },
                        2: {
                            halign: 'center',
                            // valign: 'center',
                            fontSize: '9',
                        },
                        3: {
                            halign: 'center',
                            // valign: 'center',
                            fontSize: '9',
                        },
                        4: {
                            halign: 'center',
                            // valign: 'center',
                            fontSize: '9',
                        },
                        5: {
                            halign: 'center',
                            // valign: 'center',
                            fontSize: '9',
                        },
                    }
                });

                // autoTable(doc, {
                //     // startY: 55,
                //     head: [],
                //     body: footer,
                //     margin: {right:10,left:10},
                //     styles: { lineHeight: lineHeight, cellPadding: 0 },
                //     theme: "plain",
                //     columnStyles: {
                //         0: {
                //             halign: 'left',
                //             fontSize: '9',
                //             cellWidth: 100
                //         }
                //     },
                //     avoidPageBreak: true
                // });

                let footer = []
                let footer1 = []
                footer.push(
                    [
                        "Demikian Berita Acara ini kami buat dengan sebenar-benarnya.",
                        "",
                        "",
                    ]
                )
                footer.push(["", "", ""]);
                footer.push(["", "", ""]);
                footer1.push(                    
                    [
                        "Dikirim Oleh,",
                        "Diterima Oleh,",
                        "Diketahui Oleh,",
                    ],
                    [
                        "Transporter/Pengirim",
                        "WHS SPV/PIC Logistik",
                        "BLC/OS/APJ",
                    ],
                    [
                        "", "", "",
                    ],
                    [
                        "", "", "",
                    ],
                    [
                        "", "", "",
                    ],
                    [
                        "", "", "",
                    ],
                    [
                        "(                                 )",
                        "(                                 )",
                        "(                                 )",
                    ],
                    [
                        "Tanggal:                        ",
                        "Tanggal:                        ",
                        "Tanggal:                        ",
                    ],
                )
                // Merge the two arrays
                let combinedFooter = footer.concat(footer1);
                
                autoTable(doc, {
                    // startY: 55,
                    head: [],
                    body: combinedFooter,
                    margin: {right:10,left:10},
                    styles: { lineHeight: lineHeight, cellPadding: 0 },
                    theme: "plain",
                    columnStyles: {
                        0: {
                            halign: 'center',
                            fontSize: '9',
                            cellWidth: 50
                        },
                        1: {
                            halign: 'center',
                            fontSize: '9',
                            cellWidth: 50
                        },
                        2: {
                            halign: 'center',
                            fontSize: '9',
                            cellWidth: 50
                        }
                    },
                    didParseCell: function (data) {
                        // Apply different cellWidth for the first footer
                        if (data.row.index === 0) {
                            data.cell.styles.halign = 'left';
                            data.cell.styles.fontSize = '9';
                            data.cell.styles.cellWidth = 100;
                        }
                    },
                    pageBreak: "avoid"
                });

                // let footer2 = []
                // footer2.push(
                //     [
                //         "No. Dokumen: " + resp.data.code,
                //         "Tanggal Efektif: " + moment().format("DD MMM YYYY")
                //     ],
                //     [
                //         "No. Rev: -",
                //         ""
                //     ]
                // )
                // autoTable(doc, {
                //     // startY: 55,
                //     head: [],
                //     body: footer2,
                //     margin: {right:10,left:10},
                //     theme: "plain",
                //     columnStyles: {
                //         0: {
                //             halign: 'left',
                //             fontSize: '9',
                //             cellWidth: 200
                //         },
                //         1: {
                //             halign: 'left',
                //             fontSize: '9',
                //         }
                //     }
                // });

                const pageCount = doc.internal.getNumberOfPages();
                for(var i = 1; i <= pageCount; i++) {
                    doc.setFontSize(15, "bold"); //.setFontStyle("bold");
                    doc.text("BERITA ACARA PENERIMAAN BARANG ", 150, 8, { align: "center" }); //(text,x,y)
                    doc.setFontSize(13, "bold");
                    doc.text(resp.data.code, 150, 13, { align: "center" }); //(text,x,y)

                    // Go to page i
                    doc.setPage(i);
                    doc.setFontSize(10);
                    doc.text('Halaman ' + String(i) + ' dari ' + String(pageCount),300-20,230-25,null,null,"right");
                }
                doc.save(
                    "Inbound-GR-" + resp.data.code + ".pdf"
                );

                this.$vs.loading.close();
            })
            .catch((e) => {
            this.$vs.loading.close();
            });
        },
        utcToLocal(val) {
            if (val) {
                return moment(val)
                    .add(-7, "h")
                    .utc()
                    .local()
                    .format("DD MMM YYYY HH:mm:ss");
            }
        },
    },
    mounted() { },
    watch: {
        draw() {
            this.getData();
        },
    },
    computed: {
        setPage: {
            get() {
                return 1;
            },
            set(val) {
                if (this.isGetData){
                    this.handleChangePage(val);
                }
            },
        },
        formatDate: () => {
            return (val) => {
                const momentDate = moment.utc(val);
                return momentDate.format('YYYY-MM-DD');
            };
        },
        formatLocalNumber: () => {
            return (val) => {
                return Number(val).toLocaleString("id-ID");
            };
        },
    },
};
</script>
<style>
.vs-dialog-warning .vs-dialog footer .vs-button-primary{
    display: none;
}

.vs-dialog-success .vs-dialog footer .vs-button-primary{
    display: none;
}
</style>