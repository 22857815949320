<template>
  <div>
    <vx-card title="Inbound - Purchase Order">
      <vs-tabs value="0">
        <vs-tab index="0" label="Purchase Order"></vs-tab>
        <vs-tab @click="goInterWarehouse" index="1" label="Inter Warehouse"></vs-tab>
        <vs-tab @click="goCustomerReturn" index="2" label="Customer Return"></vs-tab>
        <vs-tab @click="goPodReturn" index="3" label="POD Return"></vs-tab>
      </vs-tabs>
      <div class="mt-3 ml-1 vx-row">
        <vs-tabs>
          <vs-tab label="Receive Check">
            <tab-received></tab-received>
          </vs-tab>
          <vs-tab label="Put-away">
            <TabPutAway></TabPutAway>
          </vs-tab>
          <vs-tab label="Complete">
            <tab-completed></tab-completed>
          </vs-tab>
          <!-- <vs-tab label="Log Import">
            <tab-log-import></tab-log-import>
          </vs-tab> -->
        </vs-tabs>
      </div>
    </vx-card>
  </div>
</template>
<script>
import TabReceived from "./component/TabReceived.vue";
// import TabHuConversion from "./component/TabHuConversion.vue";
import TabPutAway from "./component/TabPutAway.vue";
import TabCompleted from "./component/TabCompleted.vue";
// import TabLogImport from "./component/TabLogImport.vue";
export default {
  components: {
    TabReceived,
    // TabHuConversion,
    TabPutAway,
    TabCompleted,
    // TabLogImport,
  },
  mounted() {
    this.$vs.loading.close();
    localStorage.removeItem('inbound-po-data')
    localStorage.removeItem('selectedSrcWarehouses')
    localStorage.removeItem('selectedSuppliers')
    localStorage.removeItem('selectedPoCodesReceived')
  },
  data() {
    return {
      activeTab: "Received",
      draw: 0,
      transactionDate: null,
      selectedSrcWarehouse: null
    }
  },
  methods: {
    handleFilter() {
      this.draw++;
    },
    goInterWarehouse() {
      this.$router.push({
        name: this.$store.state.inbound.interWarehouse.baseRouterName,
      });
    },
    goCustomerReturn() {
      this.$router.push({
        name: this.$store.state.inbound.customerReturn.baseRouterName,
      });
    },
    goPodReturn() {
      this.$router.push({
        name: this.$store.state.inbound.podReturn.baseRouterName,
      });
    },
  }
}
</script>
